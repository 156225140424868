import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export const openPlanLayoutPanel = (params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const { editorSDK, componentRef, flowAPI } = params;

  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.plan-card.layout-panel.title'),
      url: getPanelUrl('PlanListWidget', 'PlanLayout'),
      componentRef,
      width: 288,
      height: flowAPI.environment.isClassicEditor ? 166 : 198,
      initialData: {
        componentRef,
      },
    },
    (token) => editorSDK.editor.showPanelPreloader(token),
  );
};
